<template>
  <div id="Africa">
    <div class="content_box">
      <div class="inner_max">
        <div class="form_deposit">
          <h4>{{ $t(countrySetup[countryCode].header) }}</h4>
          <div class="content">
            <div class="info_box">
              <img src="@/assets/images/channel/local_transfer.png" alt="" />
              <p class="mb-4 text-left">
                {{ $t('deposit.method.desc', { method: $t(countrySetup[countryCode].header) }) }}
              </p>
              <ul>
                <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
                <li>
                  {{ $t('deposit.method.inst2', { number: '2', country: $t(countrySetup[countryCode].country) }) }}
                </li>
                <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
              </ul>
            </div>
            <div class="form_main">
              <p class="title">
                {{ $t('deposit.method.form.header', { method: $t(countrySetup[countryCode].header) }) }}
              </p>
              <el-form label-position="top" :model="zotaForm" ref="zotaForm" status-icon :rules="rules">
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <AccountNumber
                        supportedCurrencies="USD"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="zotaForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="zotaForm.notes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                    <li class="fr" v-if="countryCode !== congoCountryCode">
                      <p class="mb-2">
                        <span class="required_icon">*</span>
                        {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: countryCurrency }) }}
                        <span>{{ rate }}</span>
                      </p>
                      <p>
                        <span class="required_icon">*</span>{{ countryCurrency + ':' }}<span>{{ rateChange }}</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import zotapayMixin from '@/mixins/page/deposit/zotapay';
import mixin from '@/mixins/page/deposit';
import { apiQueryExchangeRate, apiZotapay_bankwire_payment } from '@/resource';

export default {
  name: 'Africa',
  components: { NumericInput, AccountNumber },
  mixins: [mixin, zotapayMixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };
    return {
      zotaForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      rules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.zotaForm.accountNumber = accountNumber;
    },
    submitDeposit() {
      return apiZotapay_bankwire_payment(
        {
          mt4Account: this.zotaForm.accountNumber,
          operateAmount: this.zotaForm.amount,
          applicationNotes: this.zotaForm.notes,
          countryCode: this.countryCode,
          depositAmount: this.countryCode !== this.congoCountryCode ? this.rateChange : 0,
          rate: this.countryCode !== this.congoCountryCode ? this.rate : 0
        },
        this.token
      );
    },
    queryRate() {
      if (this.countryCode !== this.congoCountryCode) {
        apiQueryExchangeRate()
          .then(resp => {
            if (resp.data.code == 0) this.rate = resp.data.data;
          })
          .catch(err => {
            this.errorMessage(
              this.$t('deposit.default.rate.result.rateError', {
                oldCurrency: 'USD',
                newCurrency: this.countryCurrency
              })
            );
          });
      }
    }
  },
  mounted() {
    this.queryRate();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
